import React from 'react'
import Helmet from 'react-helmet'

import { StaticQuery, graphql } from 'gatsby'

export default ({ children, title }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={data => (
			<>
				<Helmet
					title={(title ? `${title} · ` : '') + data.site.siteMetadata.title}
					meta={[
						{ name: 'author', content: 'Avi Aryan' },
						{ name: 'viewport', content: 'width=device-width' },
					]}
					link={[
						{ rel: 'icon', href: '/favicon.png' },
						{ rel: 'author', href: 'https://twitter.com/aviaryan123' },
					]}
				/>
				{children}
			</>
		)}
	/>
)
